/* eslint-disable import/prefer-default-export */
import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';

const dependentProperties = {
  NAME: 'name',
  PROJECT: 'project',
  ADDRESS: 'address',
};

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'buildings.project_name',
        type: schemaItemTypes.PROJECT,
        prop: dependentProperties.PROJECT,
        rules: [rules.REQUIRED],
        size: 3,
      },
      {
        label: 'buildings.address',
        type: schemaItemTypes.STRING,
        prop: dependentProperties.ADDRESS,
        rules: [rules.REQUIRED],
        size: 3,
      },
      {
        label: 'buildings.number_of_units',
        type: schemaItemTypes.NUMBER,
        prop: 'numberOfUnits',
        rules: [rules.REQUIRED],
        size: 3,
      },
    ],
  },
];
