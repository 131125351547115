<template>
  <div>
    <!-- @todo add edit route -->
    <entity-header
      class="mb-10"
      :title="building.name"
      :is-archived="building.isArchived"
      :back-route="backRoute"
      :edit-route="editRoute"
      :can-update="canUpdateProjects"
      @archive="archive"
    />

    <div v-if="isLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>
    <view-builder v-else :schema="$options.schema" :data="data" />
  </div>
</template>

<script>
// Models
import { schema } from '@/schemas/building.view.schema';

// Services
import realtyService from '@/services/realty';
import analyticsService from '@/services/analytics';

// Constants
import { BUILDINGS_EDIT, PROJECTS_ARCHIVE, PROJECTS } from '@/constants/routes';
import { BUILDING_VIEWING, ARCHIVE_BUILDING, UNARCHIVE_BUILDING } from '@/constants/analyticsActions';
import { PROJECTS as PROJECTS_SUBJECT } from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

import ViewBuilder from '@/components/schema/ViewBuilder.vue';
import EntityHeader from '@/components/EntityHeader.vue';

export default {
  name: 'Buildings',

  components: { EntityHeader, ViewBuilder },

  data() {
    return {
      isLoading: false,
      building: {},
      id: this.$route.params.id,
    };
  },

  computed: {
    canUpdateProjects() {
      return this.$can(UPDATE, PROJECTS_SUBJECT);
    },

    backRoute() {
      const routeName = this.building.isArchived ? PROJECTS_ARCHIVE : PROJECTS;

      return { name: routeName, query: { page: this.$route.query.prevPage || 1 } };
    },

    editRoute() {
      return { name: BUILDINGS_EDIT, params: { id: this.$route.params.id } };
    },

    data() {
      return {
        project: this.building.project,
        numberOfUnits: this.building?.numberOfUnits,
        address: this.building?.address,
      };
    },
  },

  mounted() {
    analyticsService.track(BUILDING_VIEWING);
    this.isLoading = true;

    realtyService
      .getBuildingById(this.id)
      .then(building => {
        this.building = building;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  methods: {
    archive() {
      analyticsService.track(this.building.isArchived ? UNARCHIVE_BUILDING : ARCHIVE_BUILDING);
      realtyService.archiveBuilding(this.id, !this.building.isArchived).then(() => {
        this.building.isArchived = !this.building.isArchived;
      });
    },
  },

  schema,
};
</script>
